var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"self-links"},[(!_vm.currentView)?_c('div',[_c('filter-levels',{attrs:{"filter-type":"self_links","extra-menu-items":[_vm.ExtraSettingsMenuItems.AdvancedSettings, _vm.ExtraSettingsMenuItems.CommonRules, _vm.ExtraSettingsMenuItems.VirtualNew]},on:{"changeCurrentView":_vm.handleCurrentView}}),_c('div',{staticClass:"main-filter-settings mt-5"},[_c('a-card',{attrs:{"title":_vm.$t('field_special_settings_title')}},[_c('strict-mode',{attrs:{"filter-type":"self_links","store-path":"$store.state.chatState.configCopy","pick-fields":[_vm.StrictModeFields.Strict_mode]}}),(Array.isArray(_vm.$store.state.chatState.configCopy.tg_links_whitelist))?_c('chips-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.$store.state.chatState.configCopy,
              'key': 'tg_links_whitelist',
              'hasAnchor': true
            }
          }}}):_c('black-white-list-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.$store.state.chatState.configCopy,
              'key': 'tg_links_whitelist',
              'hasAnchor': true
            }
          }}}),(_vm.$store.state.chatState.configCopy.tg_links_whitelist.type === 'Blacklist')?_c('nested-content',[_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'self_links_dont_use_channels',
                'tariffTags': _vm.getTagsByFieldKey('self_links_dont_use_extended'),
                'hasAnchor': true
              }
            }}}),_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'self_links_dont_use_chats',
                'tariffTags': _vm.getTagsByFieldKey('self_links_dont_use_extended'),
                'hasAnchor': true
              }
            }}}),_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'self_links_dont_use_users',
                'tariffTags': _vm.getTagsByFieldKey('self_links_dont_use_extended'),
                'hasAnchor': true
              }
            }}}),_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'self_links_dont_use_bots',
                'tariffTags': _vm.getTagsByFieldKey('self_links_dont_use_extended'),
                'hasAnchor': true
              }
            }}})],1):_vm._e(),_c('a-divider'),_c('ignore-caption',{attrs:{"filter-type":"self_links","store-path":"$store.state.chatState.configCopy"}}),_c('handle-user-bio',{attrs:{"filter-type":"self_links","store-path":"$store.state.chatState.configCopy"}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_common_settings_title')}},[_c('common-settings',{attrs:{"filter-type":"self_links","store-path":"$store.state.chatState.configCopy"}})],1)],1)],1):_c('div',[(_vm.currentView === _vm.ExtraSettingsMenuItems.AdvancedSettings)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"advanced_settings"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'self_links_dont_use_dog',
                'hasAnchor': true
              }
            }}}),_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'self_links_dont_use_join',
                'hasAnchor': true
              }
            }}}),_c('switch-input',{attrs:{"setup":{
              'func': _vm.configInputSetup,
              'args': {
                'model': _vm.$store.state.chatState.configCopy,
                'key': 'self_links_dont_use_tme',
                'hasAnchor': true
              }
            }}})]},proxy:true}],null,false,2667621763)})],1):_vm._e(),(_vm.currentView === _vm.ExtraSettingsMenuItems.VirtualNew)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"virtual_new"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('virtual-new-settings',{attrs:{"filter-type":"self_links","store-path":"$store.state.chatState.configCopy"}})]},proxy:true}],null,false,2334897413)})],1):_vm._e(),(_vm.currentView === _vm.ExtraSettingsMenuItems.CommonRules)?_c('div',[_c('section-wrapper',{attrs:{"section-title":"common_rules"},on:{"onNavBack":_vm.handleNavBack},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('common-rules-settings',{attrs:{"filter-type":"self_links","store-path":"$store.state.chatState.configCopy"}})]},proxy:true}],null,false,4277275208)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }